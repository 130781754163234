<template>
    <div>
        <div class="header">
            <span class="header_txt">福袋设置</span>
        </div>
        <div class="content">
            <Form ref="bagListForm" :model="bagListForm" :rules="bagListFormRules" :label-width="120">
                <FormItem label="抽奖名称" prop="name">
                    <Input type="text" v-model="bagListForm.name" style="width: 300px;" placeholder="请输入福袋名称"></Input>
                </FormItem>
                <FormItem label="倒计时">
                    <Input type="text" v-model="bagListForm.count_down" style="width: 300px;" placeholder="请输入内容">
                        <span slot="append">
                            <Select v-model="bagListForm.type" style="width: 100px" @on-change="typeChange">
                                <Option v-for="item in typeList" :value="item.id" :key="item.id">单位/{{ item.name }}</Option>
                            </Select>
                        </span>
                    </Input>
                </FormItem>
                <!-- <FormItem label="中奖人数" prop="num">
                    <Input type="text" v-model="bagListForm.num" style="width: 300px;" placeholder="请输入中奖人数"></Input>
                </FormItem> -->
                <FormItem label="抽奖评论">
                    <Input type="textarea" v-model="bagListForm.comment" style="width: 300px;" placeholder="请输入抽奖评论"></Input>
                </FormItem>
                <FormItem label="奖品">
                    <div class="content_prize">
                        <div>
                            <Upload
                                ref="upload"
                                :show-upload-list="false"
                                :format="['jpg', 'jpeg', 'png', 'gif']"
                                :max-size="2048"
                                :on-format-error="handleFormatError"
                                :on-success="prizeSuccess"
                                :on-progress="handleProgress"
                                :headers="headers"
                                :action="uploadImg"
                                >
                                <div class="form_upload">
                                    <img v-if="bagListForm.prize.id" style="width: 100px;height: 100px;border-radius: 10px;" :src="bagListForm.prize.path" alt="">
                                    <Icon v-else class="form_icon" type="md-add" />
                                </div>
                            </Upload>
                        </div>
                        <div class="content_prize_right">
                            <Input type="text" v-model="bagListForm.prize.name" style="width: 150px;" placeholder="请输入奖品名称"></Input>
                            <Button type="primary" @click="add" style="width: 150px;">添加</Button>
                        </div>
                    </div>
                </FormItem>
                <FormItem label="指定中奖人" prop="designated_winning">
                    <template>
                        <i-switch v-model="bagListForm.designated_winning" :true-value="1" :false-value="0"></i-switch>
                    </template>
                </FormItem>
                <FormItem v-if="bagListForm.designated_winning == 1" label="添加指定中奖人">
                    <Button type="primary" @click="addAward">添加</Button>
                </FormItem>
                <!-- 表格 -->
                <Table v-if="bagListForm.designated_winning == 1" :columns="modalTable" :data="modalDataTable" border>
                    <template slot-scope="{row}" slot="shop">
                        <span>{{row.shop_name}}</span>
                    </template>
                    <template slot-scope="{row}" slot="portrait">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                            <img v-if="row.portrait" :src="row.portrait.path" alt="" style="width: 50px;">
                            <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                        </div>
                    </template>
                    <template slot-scope="{row,index}" slot="type">
                        <!-- <Button type="primary" @click="addModals(row)">添加</Button> -->
                        <Button type="error" @click="delModals(row,index)">移除</Button>
                    </template>
                </Table>
            </Form>
            <!-- 点击添加按钮-出现 -->
            <div class="add_img">
                <div class="add_img_box" v-for="item,index in bindModalBox" :key="index">
                    <img style="width: 80px;height: 80px;" :src="item.path" alt="">
                    <span class="add_img_span">{{item.name}}</span>
                </div>
            </div>
            <div class="footer">
                <Button @click="jumpBack" style="width: 200px;height: 40px;margin-right: 10px;">取消</Button>
                <Button type="primary" @click="save('bagListForm')" style="width: 200px;height: 40px;">保存</Button>
            </div>
        </div>
        <!-- 添加指定中奖人 -->
        <Modal v-model="addModal" title="添加指定中奖人" width="50%">
            <div class="addModal_content">
                <div style="display:flex;flex-direction:row;width: 50%;margin-bottom: 20px;">
                    <Input v-model="storePramsModal.keywords" type="text" placeholder="客户名字/手机号" />
                    <Button type="primary" @click="search">搜索</Button>
                </div>
                <Table :columns="detailsTable" :data="detailsDataTable" border>
                    <template slot-scope="{row}" slot="shop">
                        <span>{{row.shop ? row.shop.name : ''}}</span>
                    </template>
                    <template slot-scope="{row}" slot="portrait">
                        <div style="display:flex;align-items:center;height: 56px;justify-content:center">
                            <img v-if="row.portrait" :src="row.portrait.path" alt="" style="width: 50px;">
                            <div v-else style="width: 56px;height: 56px;background-color: #f5f7fa;color:#c0c4cc;line-height:56px;text-align:center;">加载失败</div>
                        </div>
                    </template>
                    <template slot-scope="{row}" slot="type">
                        <Button type="primary" @click="addModals(row)">添加</Button>
                        <!-- <Button type="error" @click="delModals">移除</Button> -->
                    </template>
                </Table>
            </div>
            <div class="footer_modal">
                <Page :total="totalModal" show-elevator show-total @on-change="pageChangeModal"/>
            </div>
            <div slot="footer">
                <Button @click="addModalF">取消</Button>
                <Button type="primary" @click="addModalT">确定</Button>
            </div>
        </Modal>
    </div>
</template>

<script>
import config from "@/config/index";
import { getCookies } from "@/utils/helper";
import {addBag, bagInfo, customerList} from '@/api/index'
    export default {
        data() {
            return {
                uploadImg: config.apiUrl + "/admin/other/uploads",
                headers: {"meiye-admin-api": getCookies("meiye-admin-api")},
                modalTable: [
                    {title: '姓名',key: 'nickname',align:'center'},
                    {title: '店铺',slot: 'shop',align:'center'},
                    {title: '手机号',key: 'phone',align:'center'},
                    {title: '头像',slot: 'portrait',align:'center'},
                    {title: '操作',slot: 'type',align:'center'}
                ],
                modalDataTable: [],
                addModal: false,
                storePramsModal: {
                    page: 1,
                    limit: 30,
                    keywords:'',
                    phone: '',
                    shop_id: -1
                },
                totalModal: 3,
                bagListForm:{
                    live_id:'',
                    name:'',
                    count_down:'',
                    num:'',
                    comment:'',
                    prize: {id:'', path:'', name:''},
                    id:'',
                    designated_winning: 0,
                    winning_text: '',
                    type: 3
                },
                detailsTable: [
                    {title: '姓名',key: 'nickname',align:'center'},
                    {title: '店铺',slot: 'shop',align:'center'},
                    {title: '手机号',key: 'phone',align:'center'},
                    {title: '头像',slot: 'portrait',align:'center'},
                    {title: '操作',slot: 'type',align:'center'}
                ],
                detailsDataTable: [],
                // 福袋设置-表单验证
                bagListFormRules: {
                    name: [
                        { required: true, message: '请输入抽奖名称', trigger: 'blur' }
                    ],
                    time: [
                        { required: true, message: '请输入倒计时', trigger: 'blur' }
                    ],
                    number: [
                        { required: true, message: '请输入福袋数量', trigger: 'blur' }
                    ]
                },
                //假数据
                bindModalBox:[
                    // {img:'live/liveActivity.jpg',title:'111'},
                ],
                typeList: [
                    {id: 1,name: '分钟'},
                    {id: 2,name: '小时'},
                    {id: 3,name: '秒'}
                ]
            }
        },
        created(e){   
            let id = this.$route.query.id ? this.$route.query.id : ''
            if(id){
                //修改
                this.bagListForm.id = id
                this.bagInfo()
                // if(this.bagListForm.prize.length == 0){
                //     console.log('123456')
                // }
            }
            else {
                //新增
                this.bagListForm.name = ''
                this.bagListForm.count_down = ''
                this.bagListForm.num = 1
                this.bagListForm.comment = ''
                this.bagListForm.prize = {id:'', path:'', name:''}
                this.bagListForm.live_id = this.$route.query.liveid
                this.bagListForm.id = ''
                this.bagListForm.designated_winning = 0
                this.bagListForm.winning_text = ''
            }            
        },
        methods: {
            typeChange(e){
                this.bagListForm.type = e
            },
            delModals(item,i){
                console.log('item',item)
                this.modalDataTable.splice(i, 1)
            },
            // 添加
            addAward(){
                this.addModal = true
                this.customerList()
            },
            addModalF(){
                this.addModal = false
            },
            addModalT(){
                this.addModal = false
            },
            // search
            search(){
                this.customerList()
            },
            addModals(item){
                let user = {'id':item.id, 'nickname':item.nickname, 'portrait':item.portrait, 'phone':item.phone, 'shop_name':item.shop ? item.shop.name : ''}
                this.modalDataTable.push(user)
            },
            pageChangeModal(index){
                this.storePramsModal.page = index;
                this.customerList()
            },
            // 员工列表
            customerList(){
                customerList(this.storePramsModal).then(res => {
                    console.log('员工列表', res.data.data)
                    this.totalModal = res.data.total
                    this.detailsDataTable = res.data.data
                })
            },
            bagInfo(){
                bagInfo({id:this.bagListForm.id}).then(res => {
                    console.log('res',res.data)
                    this.bagListForm = res.data
                    this.bindModalBox = res.data.prize
                    this.bagListForm.prize = {id:'', path:'', name:''}
                    this.modalDataTable = this.bagListForm.winning_text
                })
                .catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            jumpBack(){
                this.$router.back()  
            },
            save(name){
                var data = {
                    name: this.bagListForm.name,
                    count_down: this.bagListForm.count_down,
                    num: 1,
                    comment: this.bagListForm.comment,
                    prize: this.bindModalBox,
                    live_id: this.bagListForm.live_id,
                    id:this.bagListForm.id,
                    designated_winning: this.bagListForm.designated_winning,
                    winning_text: this.modalDataTable,
                    type: this.bagListForm.type
                }
                addBag(data).then(res => {
                    this.$router.back()
                    this.$Message.success(res.msg);
                })
                return
                this.$refs[name].validate((valid) => {
                    if (valid) {
                        this.$Message.success('保存成功!');
                    }
                })
            },
            //添加奖品
            add(){
                console.log('点击了添加奖品按钮', this.bagListForm.prize)
                this.bindModalBox.push(this.bagListForm.prize)
                this.bagListForm.prize = {id:'', path:'', name:''}
            },
            //文件上传类型错误
            handleFormatError() {
                this.$Message.warning("暂不支持上传此格式");
            },
            // 文件上传时
            handleProgress() {
                const msg = this.$Message.loading({
                    content: "上传中...",
                    duration: 0,
                });
                setTimeout(msg, 3000);
            },
            // 图片上传
            prizeSuccess(e) {
                console.log("图片上传", e);
                this.bagListForm.prize.path = e.data.path
                this.bagListForm.prize.id = e.data.id
                this.$Message.destroy();
                this.$Message.success(e.msg);
            },
        },
    }
</script>

<style lang="scss" scoped>
.header{
    width: 100%;
    height: 55px;
    padding: 16px;
    background-color: #cee0f9;
    border-top-left-radius: 15px; 
    border-top-right-radius: 15px;
    display: flex;
    align-items: center;
}
.header_txt{
    font-size: 20px;
    font-weight: bold;
    color: #000;
}
.content{
    margin: 20px 0;
}
::v-deep .ivu-input-number-input{
    text-align: center;
}
.form_upload{
    width: 100px;
    height: 100px;
    border: 1px solid #409eff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}
.form_icon{
    font-size: 30px;
    color: #808080;
}
.content_prize{
    width: 280px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.content_prize_right{
    width: 150px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.add_img{
    margin: 10px 0 40px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.add_img_box{
    margin: 5px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
.add_img_span{
    width: 70px;
    // overflow:hidden;//超出部分隐藏
    // white-space:nowrap;//禁止换行
    // text-overflow:ellipsis//省略号
}
</style>